import { render, staticRenderFns } from "./landscape.vue?vue&type=template&id=18a7a8e6&scoped=true"
import script from "./landscape.vue?vue&type=script&lang=js"
export * from "./landscape.vue?vue&type=script&lang=js"
import style0 from "./landscape.vue?vue&type=style&index=0&id=18a7a8e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18a7a8e6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/codebuild/output/src4247274520/src/holaluz-conversion-conversionsolar-solarsalesagentui/src/ui/components/TheHeader/index.js').default})
